import {
  LifetimeOfBenefitsMobile,
  LifetimeBenefitsDesktop,
} from './components';
import type { BenefitCardVariant } from './data';

interface LifetimeOfBenefitsProps {
  onTileClick: (key: BenefitCardVariant) => void;
}

export const LifetimeOfBenefits = ({
  onTileClick,
}: LifetimeOfBenefitsProps) => {
  return (
    <>
      <LifetimeOfBenefitsMobile onTileClick={onTileClick} />
      <LifetimeBenefitsDesktop />
    </>
  );
};
